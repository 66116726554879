
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Layout from "../lib/layout";
import React, { useEffect } from "react";
import { API_URL } from "../lib/settings";
import CardsGrid from "../lib/cardsGrid";
import useTranslation from "next-translate/useTranslation";

export default function Index({
  popularVarieties,
  wantedVarieties,
  lastaddedVarieties,
}) {
  //const { locale, locales, defaultLocale } = context;
  const { t, lang } = useTranslation('home')
  return (
    <Layout
      title={t("title")}
      description={t("description")}
      image="/static/fl_def.jpg"
    >
      <h2>{t('popular')}</h2>
      <CardsGrid varsList={popularVarieties} />
      <h2>{t('new_violets')}</h2>
      <CardsGrid varsList={lastaddedVarieties} />
      <h2>{t('wanted_violets')}</h2>
      <CardsGrid varsList={wantedVarieties} />
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const res = await fetch(`${API_URL}/varieties/popular/?limit=12`);
  const vars = await res.json();
  const popularVarieties = vars.results;

  const res2 = await fetch(`${API_URL}/varieties/wanted/?limit=12`);
  const vars2 = await res2.json();
  const wantedVarieties = vars2.results;

  const res3 = await fetch(`${API_URL}/varieties/lastadded/?limit=12`);
  const vars3 = await res3.json();
  const lastaddedVarieties = vars3.results;

  return {
    props: {
      popularVarieties,
      wantedVarieties,
      lastaddedVarieties,
    },
    revalidate: 60,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  