import Link from "next/link";
import React, { useEffect } from "react";
import Image from "next/image";
import { myImageLoader } from "./layout";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import translit from "./translit";
export default function CardsGrid({ varsList }) {
  const { t, lang } = useTranslation('common')
  useEffect(() => {
    // console.log("varsList", varsList);
  }, [varsList]);
  return (
    <Grid container alignItems="stretch">
      {varsList &&
        varsList.length > 0 &&
        varsList.map((variety) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              key={variety.id}
              style={{ padding: "5px" }}
            >
              <Link
                href={`/${encodeURIComponent(variety.plant.slug)}/${encodeURIComponent(variety.slug)}`}
                passHref
              >
                <Card
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <CardActionArea>
                    <Box sx={{
                      position: "relative",
                      height: {
                        xs: "150px",
                        sm: "150px",
                        md: "200px",
                        lg: "250px",
                        xl: "250px"
                      }
                    }}
                    >
                      <Image
                        //src={variety.previewphoto || "/no_img.jpg"}
                        title={
                          t(variety.plant.slug) + " " + variety?.name + ", " + t("photo")
                        }
                        alt={
                          t(variety.plant.slug) + " " + variety?.name + ", " + t("photo")
                        }
                        src={variety.previewphoto_det?.photo || "/no_img.jpg"}
                        placeholder={
                          variety.previewphoto_det?.photo_blur
                            ? "blur"
                            : "empty"
                        }
                        blurDataURL={`data:image/jpg;base64,${variety.previewphoto_det?.photo_blur}`}
                        layout="fill"
                        objectFit="cover"
                        loader={myImageLoader}
                        sizes="(min-width:600px) 33vw, 50vw"
                      />
                    </Box>
                    <CardContent>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="p"
                      >
                        {lang==='en'?translit(variety.name):variety.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {lang==='en'?translit(variety.hybridizer):variety.hybridizer}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions disableSpacing>
                    <Button size="small" color="primary">
                      {t('open')}
                    </Button>
                    {variety.min_price > 0 && (
                      <Box sx={{marginLeft: "auto"}}>
                        {t('from')} {variety.min_price} {t('rub')}
                      </Box>
                    )}
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          );
        })}
    </Grid>
  );
}
